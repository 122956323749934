import React, { useState } from 'react'
import axios from 'redaxios'
import { useFormik } from 'formik'
// import Recaptcha from 'react-recaptcha'

const API_PATH = 'https://ksmail-elaxart.vercel.app/api/sendMailTeilnahme.php'
const MAIL_RECIPIENT = 'wennrich@kuboshow.de'

const BedingungenForm = () => {
  const [isErrorShown, setIsErrorShown] = useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const formik = useFormik({
    initialValues: { name: '', email: '', proof: '' },
    validate: (values) => {
      const errors = {}
      if (!values.name) errors.name = 'Feld benötigt'
      if (!values.email) errors.email = 'Feld benötigt'
      if (!values.proof) errors.proof = 'Antwort benötigt'
      if (values.proof !== 16) errors.proof = 'Falsche Antwort'
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = 'Fehlende / Ungültige E-Mail-Adresse'
      }
      return errors
    },
    onSubmit: (values, { setSubmitting }) => {
      setTimeout(() => {
        axios({
          method: 'post',
          url: `${API_PATH}`,
          data: JSON.stringify({
            to: MAIL_RECIPIENT,
            ...values,
          }),
        })
          .then((result) => {
            if (result.data.sent) {
              console.log({
                mailSent: result.data.sent,
              })
              setIsFormSubmitted(true)
            } else {
              setIsErrorShown(true)
            }
          })
          .catch((error) => {
            console.error({ error: error.message })
            setIsErrorShown(true)
          })
          .finally(setSubmitting(false))
      }, 400)
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="form-group col-12 col-md-6">
          <input
            id="name"
            placeholder="Name"
            {...formik.getFieldProps('name')}
            type="text"
            className="form-control my-input input-md"
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="text-danger">{formik.errors.name}</div>
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="form-group col-12 col-md-6">
          <input
            id="email"
            {...formik.getFieldProps('email')}
            placeholder="E-Mail-Adresse"
            type="email"
            className="form-control my-input input-md"
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-danger">{formik.errors.email}</div>
          ) : null}
        </div>
      </div>
      {!formik.errors.name && !formik.errors.email && (
        <>
          <div className="row mt-3 ">
            <div className="form-group col-6 col-md-3">
              <label className="control-label my-label" htmlFor="proof">
                Sicherheitsfrage: Was ist 6 + 1O?
              </label>
            </div>
            <div className="form-group col-4 col-md-2">
              <input
                id="proof"
                {...formik.getFieldProps('proof')}
                type="number"
                className="form-control my-input input-md"
              />
              {formik.touched.proof && formik.errors.proof ? (
                <div className="text-danger">{formik.errors.proof}</div>
              ) : null}
            </div>
          </div>
        </>
      )}
      <div className="row justify-content-center">
        <div className="form-group">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={
              formik.isSubmitting || !formik.values.proof || formik.errors.proof
            }
          >
            {formik.isSubmitting && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            <span>ABSENDEN</span>
          </button>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-10 col-md-8 text-center">
          {isFormSubmitted && (
            <p className="text-success">
              Ihre Nachricht wurde erfolgreich gesendet. Vielen Dank!
            </p>
          )}

          {isErrorShown && (
            <p className="text-danger">
              Die Nachricht konnte nicht gesendet werden. Bitte versuchen Sie es
              noch einmal zu einem späteren Zeitpunkt oder informieren Sie den
              Administrator der Webseite.
            </p>
          )}
        </div>
      </div>
    </form>
  )
}

export default BedingungenForm
