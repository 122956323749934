import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageSection from '../components/PageSection'
import BedingungenForm from '../components/BedingungenForm'

function Bedingungen({ location }) {
  return (
    <Layout location={location} showSlider={false}>
      <SEO keywords={[`kuboshow`, `teilnahme`, 'bedingungen', 'teilnahmebedingungen']} title="Teilnahmebedingungen" />
      <PageSection id="messe">
        <div className="row">
          <div className="col-12 col-md-8">
            <h2>Teilnahme- und Bewerbungsbedingungen</h2>
            <p>
              Erfahre mehr über die Teilnahme an der KUBOSHOW KUNSTMESSE und
              fordere hier die Teilnahme- und Bewerbungsbedingungen an.
            </p>
            {/* <p>Die Online-Bewerbung steht ab 1. August 2024 wieder zur Verfügung.</p> */}
            <p>Einsendeschluss für Deine Bewerbung ist der 16.9.2024.</p>
          </div>
          <div className="col-12 col-md-8 mt-3 mb-5">
            <h2>Conditions of participation and application</h2>
            <p>
              Find out more about taking part in the KUBOSHOW ONLY ONLINE and
              request the conditions of participation and application here.
            </p>
            {/* <p>The online application will be available again from 1st of August 2024.</p> */}
            <p>Deadline for your application is 16.9.2024.</p>
          </div>
        </div>
        <BedingungenForm />
      </PageSection>
    </Layout>
  )
}

export default Bedingungen
